export enum OrderStatus {
  CLOSED = 'account.orders.status.closed',
  PROCESSING = 'account.orders.status.processing',
  CANCELED = 'account.orders.status.canceled',
  NEW = 'account.orders.status.new',
  DISPATCHED = 'account.orders.status.dispatched',
  READY_FOR_PICKUP = 'account.orders.status.readyForPickup',
  COMPLETED = 'account.orders.status.completed',
  WAITING_FOR_PREPAYMENT = 'account.orders.status.waitingForPrepayment',
}

export enum OrderCancellationStatus {
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  REQUESTED = 'requested',
  PARTIAL_REQUESTED = 'partialRequested',
}
