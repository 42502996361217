import { XSELL_NEW_LAYOUT } from 'shared/experiments/consts/names'
import { trackCswSlider } from 'shared/experiments/consts/trackCswSlider'
import { isExperimentEnabled } from 'shared/experiments/utils/experiments'

export default () => {
  if (!isExperimentEnabled(XSELL_NEW_LAYOUT)) {
    return
  }
  trackCswSlider({
    shouldTrackWithWidgetIds: true,
    shouldTrackShowMoreClick: true,
    shouldTrackArrowClick: true,
  })
}
