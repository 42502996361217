import {
  TENANT_AT,
  TENANT_BE,
  TENANT_CH,
  TENANT_COM,
  TENANT_FR,
  TENANT_IT,
} from 'shared/consts/tenants'

export const TYPE_PDP_TOP = 'buyTogetherWith'
export const TYPE_CAT_PAGES_XSELL_SECOND_WID = 'categorySecond'
export const TYPE_PDP_CENTER = 'sliderAvailable'
export const TYPE_PDP_NA = 'sliderOutOfStock'
export const TYPE_PDP_AV = 'sliderNoSuccessor'
export const TYPE_PDP_RX = 'sliderForPrescriptedMedicine'
export const TYPE_START_PAGE = 'startPage'
export const TYPE_START_PAGE_BOTTOM = 'startPageBottom'
export const TYPE_CATEGORY = 'category'
export const TYPE_ADD_TO_CART_TOP = 'addToCartTop' // popular products
export const TYPE_ADD_TO_CART_CENTER = 'addToCartCenter' // recommended products
export const TYPE_CART_TOP = 'cartTop'
export const TYPE_CART_BOTTOM = 'cartBottom'
export const TYPE_NU3_ADD_TO_CART_BOTTOM = 'nu3AddToCartBottom'
export const TYPE_HOME = 'home'
export const TYPE_SEARCH_NO_RESULTS = 'sliderNoSearchResults'
export const TYPE_PDP_SLIDER_TOP = 'pdpSliderTop'
export const TYPE_PDP_SLIDER_TOP_NEW = 'pdpSliderTopNew'
export const TYPE_DELIVERY_TRACKING = 'deliveryTracking'
export const TYPE_MY_ACCOUNT_OVERVIEW = 'myAccountOverview'
export const TYPE_REDPOINTS_PAGE = 'redpoints'
export const TYPE_OWN_BRANDS_ON_HOMEPAGE = 'ownBrandsOnHomepage'
export const TYPE_CSW_ON_SERP = 'cswOnSerp'
export const TYPE_CSW_HEADER_TITLE = 'cswHeaderTitle'
export const TYPE_SELLER_MODAL = 'sellerModal'
export const TYPE_CART_CSW_THREE_PRODUCTS_EXPERIMENT = 'cartCswThreeProducts'
export const CROSSSELL_CLASSNAME = 'crosssell-widget'
export const CROSSSELL_ATTR_ANALYTICS_NAME = 'crosssell-widget'
export const ANALYTICS_ATTR_IMAGE = 'item-image'
export const ANALYTICS_ATTR_TITLE = 'item-title'

export const TYPE_RETAIL_SHOWCASE_PAGE = 'retailShowCasePage'

export const AT_TYPE_CART_TOP = 50
export const AT_TYPE_CART_BOTTOM = 51
export const MP_AT_TYPE_CART_TOP = 361
export const MP_AT_TYPE_CART_BOTTOM = 362

export const MP_COM_AID = '000016f3-496c2984-7951-4ed2-b556-1bdfdd0856c4-8'
export const MP_AT_AID = '000016f3-496c2984-7951-4ed2-b556-1bdfdd0856c4-9'
export const MP_IT_AID = '000016f3-496c2984-7951-4ed2-b556-1bdfdd0856c4-c'

export const DEFAULT_WIDGETS = {
  [TENANT_COM]: {
    [TYPE_CAT_PAGES_XSELL_SECOND_WID]: 326,
    [TYPE_PDP_AV]: 316,
    [TYPE_PDP_NA]: 298,
    [TYPE_PDP_CENTER]: 297,
    [TYPE_PDP_RX]: 324,
    [TYPE_PDP_TOP]: 296,
    [TYPE_START_PAGE]: 294,
    [TYPE_START_PAGE_BOTTOM]: 299,
    [TYPE_CATEGORY]: 295,
    [TYPE_ADD_TO_CART_TOP]: 317,
    [TYPE_ADD_TO_CART_CENTER]: 318,
    [TYPE_CART_TOP]: 320,
    [TYPE_CART_BOTTOM]: 321,
    [TYPE_NU3_ADD_TO_CART_BOTTOM]: 319,
    [TYPE_HOME]: 294,
    [TYPE_SEARCH_NO_RESULTS]: 322,
    [TYPE_PDP_SLIDER_TOP]: 323,
    [TYPE_PDP_SLIDER_TOP_NEW]: 60,
    [TYPE_DELIVERY_TRACKING]: 328,
    [TYPE_MY_ACCOUNT_OVERVIEW]: 348,
    [TYPE_RETAIL_SHOWCASE_PAGE]: 330,
    [TYPE_CART_CSW_THREE_PRODUCTS_EXPERIMENT]: 452,
    [TYPE_SELLER_MODAL]: 327,
  },
  [TENANT_AT]: {
    [TYPE_CAT_PAGES_XSELL_SECOND_WID]: 379,
    [TYPE_PDP_AV]: 377,
    [TYPE_PDP_NA]: 357,
    [TYPE_PDP_CENTER]: 356,
    [TYPE_PDP_TOP]: 354,
    [TYPE_SEARCH_NO_RESULTS]: 369,
    [TYPE_START_PAGE]: 351,
    [TYPE_START_PAGE_BOTTOM]: 376,
    [TYPE_CATEGORY]: 352,
    [TYPE_ADD_TO_CART_TOP]: 359,
    [TYPE_ADD_TO_CART_CENTER]: 360,
    [TYPE_NU3_ADD_TO_CART_BOTTOM]: 358,
    [TYPE_CART_TOP]: MP_AT_TYPE_CART_TOP,
    [TYPE_CART_BOTTOM]: MP_AT_TYPE_CART_BOTTOM,
    [TYPE_DELIVERY_TRACKING]: 380,
    [TYPE_PDP_SLIDER_TOP]: 356,
    [TYPE_MY_ACCOUNT_OVERVIEW]: 381,
    [TYPE_OWN_BRANDS_ON_HOMEPAGE]: 533,
    [TYPE_CART_CSW_THREE_PRODUCTS_EXPERIMENT]: 461,
    [TYPE_SELLER_MODAL]: 405,
  },
  [TENANT_FR]: {
    [TYPE_CAT_PAGES_XSELL_SECOND_WID]: 402,
    [TYPE_PDP_AV]: 100,
    [TYPE_PDP_NA]: 98,
    [TYPE_PDP_CENTER]: 79,
    [TYPE_PDP_TOP]: 77,
    [TYPE_SEARCH_NO_RESULTS]: 80,
    [TYPE_START_PAGE]: 74,
    [TYPE_START_PAGE_BOTTOM]: 97,
    [TYPE_CATEGORY]: 75,
    [TYPE_ADD_TO_CART_TOP]: 82,
    [TYPE_ADD_TO_CART_CENTER]: 83,
    [TYPE_NU3_ADD_TO_CART_BOTTOM]: 81,
    [TYPE_CART_TOP]: 84,
    [TYPE_CART_BOTTOM]: 85,
    [TYPE_CSW_ON_SERP]: 94,
    [TYPE_MY_ACCOUNT_OVERVIEW]: 94,
    [TYPE_CART_CSW_THREE_PRODUCTS_EXPERIMENT]: 286,
  },
  [TENANT_CH]: {
    [TYPE_PDP_AV]: 433,
    [TYPE_CAT_PAGES_XSELL_SECOND_WID]: 610,
    [TYPE_PDP_NA]: 414,
    [TYPE_PDP_CENTER]: 413,
    [TYPE_PDP_TOP]: 411,
    [TYPE_SEARCH_NO_RESULTS]: 431,
    [TYPE_START_PAGE]: 408,
    [TYPE_START_PAGE_BOTTOM]: 430,
    [TYPE_CATEGORY]: 409,
    [TYPE_ADD_TO_CART_TOP]: 416,
    [TYPE_ADD_TO_CART_CENTER]: 417,
    [TYPE_CART_TOP]: 418,
    [TYPE_CART_BOTTOM]: 415,
    [TYPE_OWN_BRANDS_ON_HOMEPAGE]: 445,
    [TYPE_CSW_HEADER_TITLE]: 427,
    [TYPE_CART_CSW_THREE_PRODUCTS_EXPERIMENT]: 432,
  },
  [TENANT_IT]: {
    [TYPE_CAT_PAGES_XSELL_SECOND_WID]: 557,
    [TYPE_PDP_AV]: 563,
    [TYPE_PDP_NA]: 543,
    [TYPE_PDP_CENTER]: 542,
    [TYPE_PDP_TOP]: 540,
    [TYPE_SEARCH_NO_RESULTS]: 555,
    [TYPE_START_PAGE]: 537,
    [TYPE_START_PAGE_BOTTOM]: 544,
    [TYPE_CATEGORY]: 538,
    [TYPE_ADD_TO_CART_TOP]: 545,
    [TYPE_ADD_TO_CART_CENTER]: 546,
    [TYPE_CART_TOP]: 547,
    [TYPE_CART_BOTTOM]: 548,
    [TYPE_PDP_SLIDER_TOP]: 560,
    [TYPE_MY_ACCOUNT_OVERVIEW]: 578,
    [TYPE_CSW_ON_SERP]: 172,
    [TYPE_CART_CSW_THREE_PRODUCTS_EXPERIMENT]: 172,
    [TYPE_PDP_RX]: 550,
    [TYPE_REDPOINTS_PAGE]: 579,
    [TYPE_SELLER_MODAL]: 586,
  },
  [TENANT_BE]: {
    [TYPE_START_PAGE]: 489,
    [TYPE_CAT_PAGES_XSELL_SECOND_WID]: 611,
    [TYPE_CATEGORY]: 490,
    [TYPE_PDP_CENTER]: 492,
    [TYPE_PDP_TOP]: 494,
    [TYPE_PDP_NA]: 495,
    [TYPE_ADD_TO_CART_TOP]: 497,
    [TYPE_ADD_TO_CART_CENTER]: 498,
    [TYPE_CART_TOP]: 499,
    [TYPE_CART_BOTTOM]: 500,
    [TYPE_START_PAGE_BOTTOM]: 511,
    [TYPE_SEARCH_NO_RESULTS]: 512,
    [TYPE_PDP_AV]: 514,
    [TYPE_OWN_BRANDS_ON_HOMEPAGE]: 513,
    [TYPE_REDPOINTS_PAGE]: 508,
    [TYPE_MY_ACCOUNT_OVERVIEW]: 510,
  },
}
