import {
  EXTENDED_SEARCHBOX_RESULTS,
  HEADER_SEARCH_BOX_SUGGESTED_PRODUCT_ADD_TO_CART,
  HEADER_SEARCH_BOX_SUGGESTED_PRODUCT_CLICK,
} from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperimentOnClient,
} from 'shared/experiments/utils/experiments'
import trackOptimizelyEvent from 'shared/experiments/utils/trackOptimizelyEvent'

export default () => {
  const { isEnabled } = getExperimentOnClient(EXTENDED_SEARCHBOX_RESULTS)

  if (!isEnabled) {
    return
  }

  document.addEventListener('click', (e: MouseEvent) => {
    const target = e.target as HTMLElement

    if (target.closest('[data-clientside-hook="SearchBoxListItemLink"]')) {
      trackOptimizelyEvent(HEADER_SEARCH_BOX_SUGGESTED_PRODUCT_CLICK)
    }

    if (
      target.closest('#AddToCartButton') &&
      window.location.search.includes('click%20on%20product%20in%20suggest')
    ) {
      trackOptimizelyEvent(HEADER_SEARCH_BOX_SUGGESTED_PRODUCT_ADD_TO_CART)
    }

    if (
      target.closest('[data-clientside-hook="h-SearchBox__trigger--mobile"]') ||
      target.closest('.o-SearchBox')
    ) {
      // if user clicks on search box and types something, activate experiment
      if (target.tagName === 'INPUT') {
        if (sessionStorage.getItem(EXTENDED_SEARCHBOX_RESULTS) === 'true') {
          return
        }
        sessionStorage.setItem(EXTENDED_SEARCHBOX_RESULTS, 'true')
        target.addEventListener(
          'input',
          () => {
            void activateExperiment(EXTENDED_SEARCHBOX_RESULTS)
          },
          { once: true }
        )
      }
    }
  })
}
