import { TENANT_AT, TENANT_COM } from 'shared/consts/tenants'
import {
  type ExperimentName,
  RELEASE_SWITCH_TOGGLE,
  RELEASE_SWITCH_TOGGLES,
  RELEASE_TOGGLE_CART_CTA_DELAYED,
  RELEASE_TOGGLE_ERX,
  RELEASE_TOGGLE_HOME_ONE,
  RELEASE_TOGGLE_MARKETPLACE,
  RELEASE_TOGGLE_MP_CROSS_SELL,
  RELEASE_TOGGLE_NOW,
  RELEASE_TOGGLE_SAE_ONLY,
  RELEASE_TOGGLE_VARIANT_ATTTRIBUTES_ENABLED,
  VARIANT_ATTRIBUTES,
} from 'shared/experiments/consts'
import type { Experiment, Tenant } from 'types/shopConfig'

export function isExperimentVariableTrue(
  key: ExperimentName,
  variable: string,
  experiments: Experiment[] = []
): boolean {
  const experiment = experiments.find(e => e.name === key)
  if (!experiment?.isEnabled) {
    return false
  }

  return experiment?.variables?.[variable] === true
}

// FIXME A temporary fix for the header issue.
//  code owner: CRO team
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getReleaseSwitchToggleCookieName = (tenant?: Tenant) =>
  RELEASE_SWITCH_TOGGLES.default

export const isCartIntermediateCtaDelayed = (
  experiments: Experiment[] = [],
  tenant?: Tenant
): boolean =>
  isExperimentVariableTrue(
    getReleaseSwitchToggleCookieName(tenant),
    RELEASE_TOGGLE_CART_CTA_DELAYED,
    experiments
  )

const saeOnlyReleasedTenants = [TENANT_COM]

export const isSaeOnly = (
  experiments: Experiment[] = [],
  tenant?: Tenant
): boolean => {
  if (tenant && saeOnlyReleasedTenants.includes(tenant)) {
    return true
  }

  return isExperimentVariableTrue(
    getReleaseSwitchToggleCookieName(tenant),
    RELEASE_TOGGLE_SAE_ONLY,
    experiments
  )
}

export const isMarketplace = (
  experiments: Experiment[] = [],
  tenant?: Tenant
): boolean =>
  isExperimentVariableTrue(
    getReleaseSwitchToggleCookieName(tenant),
    RELEASE_TOGGLE_MARKETPLACE,
    experiments
  )

export const isMarketplaceEnabledForTenant = (
  experiments: Experiment[] = [],
  tenant: Tenant
): boolean =>
  [TENANT_AT, TENANT_COM].includes(tenant) || isMarketplace(experiments, tenant)

export const isERx = (
  experiments: Experiment[] = [],
  tenant?: Tenant
): boolean =>
  isExperimentVariableTrue(
    getReleaseSwitchToggleCookieName(tenant),
    RELEASE_TOGGLE_ERX,
    experiments
  )

export const isNow = (
  experiments: Experiment[] = [],
  tenant?: Tenant
): boolean =>
  isExperimentVariableTrue(
    getReleaseSwitchToggleCookieName(tenant),
    RELEASE_TOGGLE_NOW,
    experiments
  )

export const isVariantAttributes = (experiments: Experiment[] = []) =>
  isExperimentVariableTrue(
    VARIANT_ATTRIBUTES,
    RELEASE_TOGGLE_VARIANT_ATTTRIBUTES_ENABLED,
    experiments
  )

export const isMpCrossSell = (experiments: Experiment[] = []): boolean =>
  isExperimentVariableTrue(
    RELEASE_SWITCH_TOGGLE,
    RELEASE_TOGGLE_MP_CROSS_SELL,
    experiments
  )

export const isHomeOne = (
  experiments: Experiment[] = [],
  tenant?: Tenant
): boolean =>
  isExperimentVariableTrue(
    getReleaseSwitchToggleCookieName(tenant),
    RELEASE_TOGGLE_HOME_ONE,
    experiments
  )
