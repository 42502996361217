import {
  PAGE_TYPE_ACCOUNT,
  PAGE_TYPE_CART,
  PAGE_TYPE_CHECKOUT,
  PAGE_TYPE_LOGIN,
} from 'shared/consts'
import { event } from 'shared/experiments/utils/event'

export const NEWSLETTER_MODAL_ID = 'NewsletterModal'
export const blacklistedPages = [
  PAGE_TYPE_ACCOUNT,
  PAGE_TYPE_CART,
  PAGE_TYPE_CHECKOUT,
  PAGE_TYPE_LOGIN,
]
export const EVENT_MODAL_SUBMIT = event`Newsletter modal submit`
export const EVENT_MODAL_CLOSED = event`Newsletter modal closed`
export const EVENT_MODAL_INPUT_CLICKED = event`Newsletter modal input field clicked`
