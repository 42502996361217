import {
  PRICE_ASC_KEY,
  PRICE_DESC_KEY,
  PRODUCT_NAME_ASC_KEY,
  PRODUCT_NAME_DESC_KEY,
  RELEVANCY_DESC_KEY,
  TOP_SELLER_COUNT_DESC_KEY,
} from 'shared/consts/algolia'

export const getFilterValues = searchResults => {
  const { page, hitsPerPage, nbHits } = searchResults
  // page starts from 0
  // pageNumber from 1
  const pageNumber = page + 1
  const from = nbHits > 0 ? page * hitsPerPage + 1 : 0
  const to = Math.min(pageNumber * hitsPerPage, nbHits)

  return {
    from: from || 0,
    to: to || 0,
    total: nbHits,
  }
}

export const getTranslation = (key, i18n) => {
  switch (key) {
    case RELEVANCY_DESC_KEY:
      return i18n._('general.label.sort.relevance')
    case PRICE_ASC_KEY:
      return i18n._('general.label.sort.priceAsc')
    case PRICE_DESC_KEY:
      return i18n._('general.label.sort.priceDesc')
    case PRODUCT_NAME_ASC_KEY:
      return i18n._('general.label.sort.atoz')
    case PRODUCT_NAME_DESC_KEY:
      return i18n._('general.label.sort.ztoa')
    case TOP_SELLER_COUNT_DESC_KEY:
      return i18n._('general.label.sort.topseller')
    default:
      return ''
  }
}
