import { QS, TENANT_CH } from 'shared/consts'
import { NEWSLETTER_MODAL_ID } from 'shared/experiments/browser/newsletterModalCH/consts'
import {
  getIsSourceBlacklisted,
  NL_MODAL_CH_WAIT_TIME,
  triggerNewsletterModal,
} from 'shared/experiments/browser/newsletterModalCH/utils'
import {
  EXPERIMENT_VARIATION,
  NEWSLETTER_MODAL_CH,
} from 'shared/experiments/consts'
import { activateExperiment } from 'shared/experiments/utils/experiments'
import { getExperimentPerTenantOnClient } from 'shared/experiments/utils/tenantBasedExperimentsUtils'
import store from 'shared/store'

export default () => {
  const isNLModalShown = window.sessionStorage.getItem(NEWSLETTER_MODAL_ID)
  const publicRuntimeConfig = store.getState()?.publicRuntimeConfig
  const { tenant } = publicRuntimeConfig?.publicConfig || {}
  const {
    pageType = '',
    searchUrl,
    coreShopEnvironment,
  } = publicRuntimeConfig?.pageProperties || {}

  if (
    getIsSourceBlacklisted(pageType, searchUrl) ||
    isNLModalShown ||
    tenant !== TENANT_CH ||
    coreShopEnvironment === QS
  ) {
    return
  }

  const newsletterExperiment =
    getExperimentPerTenantOnClient(NEWSLETTER_MODAL_CH)

  if (!newsletterExperiment.isEnabled) {
    return
  }

  setTimeout(() => {
    void activateExperiment(newsletterExperiment.name)
  }, NL_MODAL_CH_WAIT_TIME)

  if (newsletterExperiment.variant === EXPERIMENT_VARIATION.V1) {
    triggerNewsletterModal()
  }
}
