import { Trans } from '@lingui/react'
import classNames from 'clsx'
import propTypes from 'prop-types'

import { NOTIFICATION_TYPES } from 'shared/consts'
import { isRebrandingCOMActive } from 'shared/experiments/utils/rebranding'
import DeprecatedIcon, {
  ICONS,
} from 'views/components/atoms/Icon/DeprecatedIcon'
import IconCircleInfoOutline from 'views/components/atoms/Icon/IconCircleInfoOutline'
import IconCross from 'views/components/atoms/Icon/IconCross'
import IconErrorWarning from 'views/components/atoms/Icon/IconErrorWarning'
import IconSale from 'views/components/atoms/Icon/IconSale'
import IconTick from 'views/components/atoms/Icon/IconTick'
import ButtonWithIcon from 'views/components/molecules/ButtonWithIcon'
import setInnerHTML from 'views/utils/setInnerHTML'
import { usePageProperties } from 'views/utils/shopAndRuntimeConfigUtils'

import './Notification.scss'

const TYPE_TO_ICON_MAP = {
  info: 'circleInfo',
  warning: 'circleExclamation',
  error: 'circleCross',
  success: 'circleTick',
  attention: 'circleExclamation',
  sale: 'iconSale',
  infoNoBgColor: 'circleInfo',
  warningNoBgColor: 'circleInfo',
}

const ICON_MAP_REBRANDING = {
  info: <IconCircleInfoOutline />,
  warning: <IconCircleInfoOutline />,
  error: <IconErrorWarning />,
  success: <IconTick />,
  attention: <IconCircleInfoOutline />,
  sale: <IconSale />,
  infoNoBgColor: <IconCircleInfoOutline />,
  warningNoBgColor: <IconCircleInfoOutline />,
}

const NOTIFICATION_ALIGNMENTS = ['left', 'center']
const NOTIFICATION_ICON_ALIGNMENTS = ['center', 'top']
const NOTIFICATION_ICONS = ICONS

// This function MUST be ES5-compatible - will be stringified
/* istanbul ignore next */
function hideDismissed(id) {
  var key = 'dismissed:' + id
  var style
  try {
    if (sessionStorage.getItem(key)) {
      style = document.createElement('style')
      style.textContent = '#' + id + '{display:none}'
      document.head.appendChild(style)
    }
  } catch (_) {
    /* ignore errors */
  }
}
/* eslint-enable no-var, prefer-template, @typescript-eslint/prefer-optional-chain */

const Notification = ({
  heading,
  label,
  type,
  icon,
  customButton,
  // In order to utilise this prop you have to include dismiss script in
  // client script of the page where notification is used.
  dismissable,
  id,
  align,
  alignIcon,
  className,
  handleDismiss,
  dataQaId,
  customBgColor,
}) => {
  const { experiments } = usePageProperties()
  const isRebrandingCOMEnabled = isRebrandingCOMActive(experiments)

  const isDissmissable = dismissable && id
  const containerClassNames = classNames(className, {
    [`m-Notification m-Notification--${type} l-flex`]: true,
    'm-Notification--info': type === 'sale',
    'u-padding--sides': !isDissmissable,
    'u-padding--left': isDissmissable,
  })
  const messageClassNames = classNames({
    'l-flex__primary u-no-margin u-padding--ends m-Notification__message': true,
    'u-text-align--center': align === 'center',
  })

  const iconClassNames = classNames({
    'm-Notification__Icon':
      type !== 'infoNoBgColor' && type !== 'warningNoBgColor',
    'm-Notification__Icon-With-Info-BgColor': type === 'infoNoBgColor',
    'm-Notification__Icon-With-Warning-BgColor': type === 'warningNoBgColor',
    'm-Notification__Icon--align-top': alignIcon === 'top',
    'm-Notification__Icon--align-center': alignIcon === 'center',
  })

  let renderedIcon = null
  // force rebrandingCOM version to have icon
  if (isRebrandingCOMEnabled) {
    renderedIcon = ICON_MAP_REBRANDING[type]
  } else if (icon === true && !isRebrandingCOMEnabled) {
    renderedIcon = <DeprecatedIcon icon={TYPE_TO_ICON_MAP[type]} />
  } else if (typeof icon === 'string') {
    renderedIcon = <DeprecatedIcon icon={icon} />
  } else {
    renderedIcon = icon
  }

  return (
    <>
      {!isDissmissable || handleDismiss ? null : (
        <script
          {...setInnerHTML(
            `(${hideDismissed.toString()}(${JSON.stringify(id)}))`
          )}
        />
      )}
      <aside
        id={id || undefined}
        className={containerClassNames}
        style={customBgColor && { 'backgroundColor': customBgColor }}
        {...(dataQaId && { 'data-qa-id': dataQaId })}
      >
        {renderedIcon && <div className={iconClassNames}>{renderedIcon}</div>}
        <div className={messageClassNames}>
          {heading ? (
            <h2 className="m-Notification__message__heading">{heading}</h2>
          ) : null}
          {label}
        </div>
        {customButton}
        {!isDissmissable ? null : (
          <ButtonWithIcon
            className={handleDismiss ? '' : 'm-Notification__dismiss'}
            icon={<IconCross />}
            component="ButtonTransparent"
            name="dismiss"
            isClientsideControlled={!handleDismiss}
            ariaControls={id}
            data-clientside-hook="dismiss"
            data-transition-out-class="m-Notification--disappear"
            data-dismissed-class="m-Notification--dismissed"
            onClick={handleDismiss}
          >
            <Trans id="general.link.label.close" />
          </ButtonWithIcon>
        )}
      </aside>
    </>
  )
}

Notification.propTypes = {
  heading: propTypes.string,
  label: propTypes.node.isRequired,
  type: propTypes.oneOf(Object.values(NOTIFICATION_TYPES)),
  icon: propTypes.oneOfType([
    propTypes.node,
    propTypes.oneOf([true, null, ...NOTIFICATION_ICONS]),
  ]),
  customButton: propTypes.oneOf([propTypes.node, null]),
  dismissable: propTypes.bool,
  id: propTypes.string,
  align: propTypes.oneOf(NOTIFICATION_ALIGNMENTS),
  alignIcon: propTypes.oneOf(NOTIFICATION_ICON_ALIGNMENTS),
  className: propTypes.string,
  handleDismiss: propTypes.func,
  dataQaId: propTypes.string,
  customBgColor: propTypes.string,
}

Notification.defaultProps = {
  heading: null,
  customButton: null,
  type: NOTIFICATION_TYPES.INFO,
  icon: true,
  dismissable: false,
  id: null,
  align: NOTIFICATION_ALIGNMENTS[0],
  alignIcon: NOTIFICATION_ICON_ALIGNMENTS[0],
  className: '',
  handleDismiss: null,
  dataQaId: null,
  customBgColor: null,
}

export { NOTIFICATION_ALIGNMENTS, NOTIFICATION_ICONS, NOTIFICATION_TYPES }
export default Notification
