import logger from 'shared/services/logger'
import store, { subscribe } from 'shared/store'
import {
  BIND_MODAL,
  DESTROY_MODAL,
  HIDE_MODAL,
  hideModalAction,
  MODAL_SHOWN,
  modalShownAction,
  REFRESH_MODALS,
  SHOW_MODAL,
} from 'shared/store/ducks/events'
import {
  clearAnimationOnAnimComplete,
  removeClearAnimationOnAnimCompleteListener,
} from 'views/components/molecules/Modal/removeDialogAnimation'
import { BREAKPOINT_IE11, BREAKPOINT_SMALL_TINY } from 'views/consts'
import createA11Dialog from 'views/utils/createA11Dialog'

import { NO_OVERFLOW_CLASS } from './consts'
import { MODAL_SELECTOR } from './MediaGallery/consts'

const MODAL_MAX_HEIGHT_PERCENT = 90
const DIALOG_SHOW_ATTR = 'data-a11y-dialog-show'
const DIALOG_HIDE_WITH_LINK_ATTR = 'data-a11y-dialog-hide-with-link'
const SELECTOR_ROOT = '[data-clientside-hook~="root"]'
const SELECTOR_DIALOG_SHOW = `[${DIALOG_SHOW_ATTR}]`
const SELECTOR_DIALOG_HIDE = '[data-a11y-dialog-hide]'
const SELECTOR_DIALOG_HIDE_WITH_LINK = `[${DIALOG_HIDE_WITH_LINK_ATTR}]`
const MODAL_INIT_CLASS_NAME = 'initShow'
const SELECTOR_MODAL_DIALOG = '.m-Modal__dialog'

const MODALS = {}

const preventDefaultHanlder = event => event.preventDefault()

const unlinkifyTriggers = modalElement => {
  const modalId = modalElement.id
  const modalFragmentHref = `#${modalId}`

  ;[
    ...Array.from(document.querySelectorAll(SELECTOR_DIALOG_SHOW)).filter(
      trigger => trigger.getAttribute('href') === modalFragmentHref
    ),
    ...modalElement.querySelectorAll(SELECTOR_DIALOG_HIDE),
  ].forEach(trigger => {
    trigger.removeEventListener('click', preventDefaultHanlder)
    trigger.addEventListener('click', preventDefaultHanlder)
  })
}

/*
 * Adds event listener to destroy modal to elements with
 * "data-a11y-dialog-hide-with-link" attribute.
 * This case is for links/buttons that you don't want to prevent
 * default functionality
 */
const addCloseTriggerToLink = modalElement => {
  ;[...modalElement.querySelectorAll(SELECTOR_DIALOG_HIDE_WITH_LINK)].forEach(
    triggerHtmlElement => {
      const modalName = triggerHtmlElement.getAttribute(
        DIALOG_HIDE_WITH_LINK_ATTR
      )

      triggerHtmlElement.addEventListener('click', () => {
        const modalToHide = MODALS[modalName]
        if (modalToHide) {
          modalToHide.destroy()
        }
      })
    }
  )
}

const refreshModals = selector => {
  const container = document.querySelector(selector)

  const modalButtons = [
    ...(container?.querySelectorAll(SELECTOR_DIALOG_SHOW) || []),
  ]
  const modalNames = Array.from(
    new Set(
      modalButtons.map(modalButton =>
        modalButton.getAttribute(DIALOG_SHOW_ATTR)
      )
    )
  )

  modalNames.forEach(modalId => {
    const modal = MODALS[modalId]
    if (modal) {
      if (modal.shown) {
        //hide current shown modal
        modal.hide()
        //recreate it and rebind event clicks
        unlinkifyTriggers(modal.$el)
        addCloseTriggerToLink(modal.$el)
        modal.create()
        //with reloaded data show it again
        modal.show()
      } else {
        //recreate it and rebind event clicks
        unlinkifyTriggers(modal.$el)
        addCloseTriggerToLink(modal.$el)
        modal.create()
      }
    }
  })
}

let modalOpenedByLocationHash = null
const showModalTargettedByLocationHash = a11dialogs => {
  const locationTargetId = window.location.hash.substring(1)

  if (!locationTargetId) {
    if (modalOpenedByLocationHash) {
      modalOpenedByLocationHash.hide()
      modalOpenedByLocationHash = null
    }
    return
  }

  const targettedModal = a11dialogs.find(
    dialog => dialog._id === locationTargetId
  )
  if (!targettedModal) {
    return
  }

  // reset DOM state
  targettedModal.$el.removeAttribute('open')
  // reset A11YDialog’s state
  targettedModal.shown = false

  targettedModal.show()

  modalOpenedByLocationHash = targettedModal
}

const updateLocationHashOnModalClose = modal => {
  if (modal === modalOpenedByLocationHash) {
    modalOpenedByLocationHash = null
    window.location.hash = ''
  }
}

export const renderModalForViewport = (modalElement, modal) => {
  if (
    modalElement.classList.contains(MODAL_INIT_CLASS_NAME) &&
    !window.matchMedia(BREAKPOINT_SMALL_TINY).matches
  ) {
    modal.show(modalElement)
  }
}

export const createModals = () => {
  const bodyElement = document.body
  const rootElement = bodyElement.querySelector(SELECTOR_ROOT)

  return [...document.querySelectorAll(MODAL_SELECTOR)].map(modalElement => {
    const modalId = modalElement.getAttribute('id')

    unlinkifyTriggers(modalElement)

    const existingModal = MODALS[modalId]
    if (existingModal?.$el === modalElement) {
      return existingModal
    }

    const modal = createA11Dialog({ element: modalElement })
    addCloseTriggerToLink(modalElement)

    let scrollFromTop = 0

    modal.on('show', () => {
      bodyElement.classList.add(NO_OVERFLOW_CLASS)
      scrollFromTop = window.pageYOffset || document.documentElement.scrollTop

      if (rootElement && window.matchMedia(BREAKPOINT_SMALL_TINY).matches) {
        rootElement.classList.add(NO_OVERFLOW_CLASS)
      }

      // IE 11 overflow fix
      if (window.matchMedia(BREAKPOINT_IE11).matches) {
        const bodyMaxHeight =
          (window.innerHeight / 100) * MODAL_MAX_HEIGHT_PERCENT -
          modalElement.querySelector('.m-Modal__header').clientHeight
        modalElement.querySelector(
          '.m-Modal__content'
        ).style.maxHeight = `${bodyMaxHeight}px`
      }

      store.dispatch(modalShownAction(modalId))
    })

    modal.on('hide', () => {
      bodyElement.classList.remove(NO_OVERFLOW_CLASS)

      if (rootElement && window.matchMedia(BREAKPOINT_SMALL_TINY).matches) {
        rootElement.classList.remove(NO_OVERFLOW_CLASS)
        window.scrollTo(0, scrollFromTop)
      }
      store.dispatch(hideModalAction(modalId))

      updateLocationHashOnModalClose(modal)
    })

    MODALS[modalId] = modal

    renderModalForViewport(modalElement, modal)

    return modal
  })
}

export default () => {
  // @ts-ignore
  subscribe.after.once(BIND_MODAL, ({ selector }) => {
    if (!selector) {
      logger.error(`Invalid payload for "${BIND_MODAL}" provided`)
      return
    }
    refreshModals(selector)
  })

  // @ts-ignore
  subscribe.after(REFRESH_MODALS, ({ selector }) => {
    if (!selector) {
      logger.error(`Invalid payload for "${REFRESH_MODALS}" provided`)
      return
    }

    refreshModals(selector)
  })

  // @ts-ignore
  subscribe.after.once(SHOW_MODAL, payload => {
    try {
      const { modalName } = payload || {}
      const modal = MODALS[modalName]
      modal.show()
    } catch (error) {
      logger.error(`"${SHOW_MODAL}" failed to dispatch`, { error })
    }
  })

  // @ts-ignore
  subscribe.after.once(DESTROY_MODAL, payload => {
    try {
      const { modalName } = payload || {}
      const modal = MODALS[modalName]
      modal.destroy()
    } catch (error) {
      logger.error(`"${DESTROY_MODAL}" failed to dispatch`, { error })
    }
  })

  // Add no animation class after animation complete after modal is opened
  subscribe.after(MODAL_SHOWN, ({ modalName }) => {
    const modal = MODALS[modalName]
    const dialog = modal?.$el.querySelector(SELECTOR_MODAL_DIALOG)

    clearAnimationOnAnimComplete(dialog)
  })

  // Remove no animation class when modal is closed
  subscribe.after(HIDE_MODAL, ({ modalName }) => {
    const modal = MODALS[modalName]
    const dialog = modal?.$el.querySelector(SELECTOR_MODAL_DIALOG)

    removeClearAnimationOnAnimCompleteListener(dialog)
  })

  const a11dialogs = createModals()

  const bondShowModalTargettedByLocationHash =
    showModalTargettedByLocationHash.bind(null, a11dialogs)

  bondShowModalTargettedByLocationHash()
  window.addEventListener('hashchange', bondShowModalTargettedByLocationHash)

  return a11dialogs
}
