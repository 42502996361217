import {
  MY_THERAPY_PAGES,
  PAGE_TYPE_ACCOUNT,
  PAGE_TYPE_CHECKOUT,
  PAGE_TYPE_LOGIN,
  PAGE_TYPE_NEWSLETTER,
} from 'shared/consts'
import logger from 'shared/services/logger'
import store from 'shared/store'

type UpdateCountdownArgs = {
  days: string
  hours: string
  minutes: string
  seconds: string
}

const ONE_SECOND = 1000
const ONE_MINUTE = ONE_SECOND * 60
const ONE_HOUR = ONE_MINUTE * 60
const ONE_DAY = ONE_HOUR * 24

const updateCountdownElement = ({
  days,
  hours,
  minutes,
  seconds,
}: UpdateCountdownArgs) => {
  const fields = ['days', 'hours', 'minutes', 'seconds']
  const values = [days, hours, minutes, seconds]

  fields.forEach(field => {
    const element = document.querySelector(
      `[data-clientside-hook~="stickyBanner-${field}"]`
    )

    if (days === '0' && field === 'days' && element) {
      element?.parentElement?.remove()
    }

    if (element) {
      element.textContent = values[fields.indexOf(field)].padStart(2, '0')
    }
  })
}

// calculates and returns the countdown
const calculateAndGetCountdown = (date: Date) => {
  const now = new Date()
  const diffInMiliSeconds = date.getTime() - now.getTime()

  if (diffInMiliSeconds < 0) {
    return {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
      done: true,
    }
  }

  // get the remaining hours,minutes,seconds as miliseconds
  // and convert them to human-readable strings
  const days = Math.floor(diffInMiliSeconds / ONE_DAY).toString()
  const hours = Math.floor((diffInMiliSeconds % ONE_DAY) / ONE_HOUR).toString()
  const minutes = Math.floor(
    (diffInMiliSeconds % ONE_HOUR) / ONE_MINUTE
  ).toString()
  const seconds = Math.floor(
    (diffInMiliSeconds % ONE_MINUTE) / ONE_SECOND
  ).toString()

  return {
    days,
    hours,
    minutes,
    seconds,
    done: false,
  }
}

const calculateCountdownAndUpdate = (date: Date) => {
  const countdown = calculateAndGetCountdown(date)

  updateCountdownElement(countdown)

  if (!countdown.done) {
    setTimeout(() => {
      calculateCountdownAndUpdate(date)
    }, 1000)
  }
}

const handleVoucherCopy = () => {
  const stickyBannerCopyVoucher = document.querySelector(
    '.o-StickyBanner-voucherCode'
  )
  stickyBannerCopyVoucher?.addEventListener('click', async () => {
    const voucherCodeButton = document.querySelector(
      '[data-clientside-hook~="stickyBannerVoucherCopyButton"]'
    )
    const voucherCode = document.querySelector(
      '.o-StickyBanner-voucherCode'
    ) as HTMLElement

    if (voucherCodeButton && voucherCode) {
      await navigator.clipboard.writeText(
        voucherCode?.dataset.voucherCode as string
      )
      voucherCode.textContent = voucherCode.dataset.textCopied as string
      voucherCodeButton?.classList.add('o-StickyBanner-voucherCode--copied')

      setTimeout(() => {
        voucherCodeButton.classList.remove('o-StickyBanner-voucherCode--copied')
        voucherCode.textContent = voucherCode.dataset.voucherCode as string
      }, 2000)
    }
  })
}

const PAGES_TO_DISABLE_STICKY_BANNER = [
  PAGE_TYPE_CHECKOUT,
  PAGE_TYPE_ACCOUNT,
  PAGE_TYPE_LOGIN,
  PAGE_TYPE_NEWSLETTER,
  ...MY_THERAPY_PAGES,
]

export default () => {
  const stickyBanner = document.querySelector(
    '[data-clientside-hook~="stickyBanner"]'
  )
  const stickyBannerClose = document.querySelector(
    '[data-clientside-hook~="stickyBannerClose"]'
  )

  const { pageType } = store.getPublicRuntimeConfig().pageProperties

  if (
    stickyBanner &&
    stickyBanner instanceof HTMLElement &&
    stickyBanner.dataset.options &&
    stickyBannerClose &&
    !PAGES_TO_DISABLE_STICKY_BANNER.includes(pageType)
  ) {
    try {
      const options = JSON.parse(stickyBanner.dataset.options) as {
        id: string
        type: 'countdown' | 'generic'
        startDate: string
        endDate: string
        blacklistedUrls: string
      }
      const isStartDatePassed = new Date(options.startDate) < new Date()
      const isEndDatePassed = new Date(options.endDate) < new Date()
      const isBlacklisted = options.blacklistedUrls.includes(
        window.location.pathname
      )

      if (
        isBlacklisted ||
        !isStartDatePassed ||
        isEndDatePassed ||
        window.sessionStorage.getItem(options.id)
      ) {
        return
      }

      if (options.type === 'countdown') {
        calculateCountdownAndUpdate(new Date(options.endDate))
        handleVoucherCopy()
      }
      // make the banner visible
      stickyBanner.classList.remove('u-hidden')
      document.body.classList.add('withNlBanner')

      // handle close button click
      stickyBannerClose.addEventListener(
        'click',
        () => {
          stickyBanner.remove()
          window.sessionStorage.setItem(options.id, 'true')
          document.body.classList.remove('withNlBanner')
        },
        {
          once: true,
        }
      )
    } catch (e) {
      logger.error(e)
    }
  }
}
