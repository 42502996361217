import { isSaeOnly } from 'shared/experiments/utils/featureFlags'
import logger from 'shared/services/logger'
import store, { subscribe } from 'shared/store'
import { REFRESH_MODALS } from 'shared/store/ducks/events'
import buildClientAPI from 'views/providers/clientAPI'

import attachScript from './attachScript'

/**
 * This script looks for all elements which has an attribute namely data-clientside-hook="nowWidget"
 * Attaches click event to the found elements. The click event when triggered in turn invokes NOW! Modal
 * As we need to send PZN/UPID to NOW Modal API, all elements with data-clientside-hook="nowWidget" should also need to have another attribute namely data-product-identifier={pzn or upid}
 * The above-mentioned PZN/UPID will be extracted and sent to now modal api
 */

const nowProduct = {
  'normal': 0,
  'sameDayNextDay': 1,
  'nextDay': 2,
}

export const openNowModal = event => {
  const identifier = event.currentTarget?.selectedIdentifier // Can be UPID or PZN

  // @ts-ignore
  let nowModalApi = window.NowAPIInfo

  if (typeof nowModalApi !== 'undefined') {
    nowModalApi.openInfoModal(null, identifier, nowProduct.sameDayNextDay)
  } else {
    const {
      publicConfig,
      pageProperties: { experiments },
    } = store.getPublicRuntimeConfig()
    attachScript({
      src: publicConfig.nowWidget.url,
      callback: async () => {
        const { isLoggedIn } = store.getState().userSession
        let plz

        const isSAERelease = isSaeOnly(experiments, publicConfig.tenant)
        if (isLoggedIn) {
          const { addressService } = buildClientAPI()

          if (isSAERelease) {
            plz = await addressService.getDefaultZip()
          } else {
            plz = (await addressService.getBillingAddress())?.zip
          }
        }

        // @ts-ignore
        nowModalApi = window.NowAPIInfo

        nowModalApi.setShop(
          publicConfig.nowWidget.shopName,
          publicConfig.nowWidget.shopEnv
        )
        nowModalApi.openInfoModal(plz, identifier, nowProduct.sameDayNextDay)
      },
      error: error => {
        logger.error(error)
      },
    })
  }
}

const isValidEnv = () => {
  const { publicConfig } = store.getPublicRuntimeConfig()

  return (
    publicConfig.nowWidget &&
    [
      publicConfig.nowWidget.url,
      publicConfig.nowWidget.shopName,
      publicConfig.nowWidget.shopEnv,
    ].every(Boolean)
  )
}

const getNowModalElements = selector => {
  const htmlElement = selector ? document.querySelector(selector) : document
  const widgets = htmlElement?.querySelectorAll(
    '[data-clientside-hook~="nowWidget"]'
  )

  return widgets?.length ? [...widgets] : []
}

const bindModals = selector => {
  if (!isValidEnv()) {
    return
  }

  getNowModalElements(selector).forEach(element => {
    const attributeIdentifierValue = element.getAttribute(
      'data-product-identifier'
    )

    element.removeEventListener('click', openNowModal)
    element.addEventListener('click', openNowModal)
    if (attributeIdentifierValue) {
      element.selectedIdentifier = attributeIdentifierValue
    }
  })
}

export default () => {
  bindModals()
  // @ts-ignore
  subscribe.after(REFRESH_MODALS, ({ selector }) => {
    bindModals(selector)
  })
}
