import {
  TENANT_AT,
  TENANT_BE,
  TENANT_CH,
  TENANT_COM,
  TENANT_FR,
  TENANT_IT,
} from 'shared/consts'

import type { TenantBasedExperiment } from './types'

export const SERP_PRE_QUERY_SUGGESTIONS_PER_TENANT_EXPERIMENT: TenantBasedExperiment =
  {
    [TENANT_COM]: { desktop: 'cro-2500', mobile: 'cro-2501' },
    [TENANT_AT]: { desktop: 'cro-2484', mobile: 'cro-2485' },
    [TENANT_FR]: { desktop: 'cro-2491', mobile: 'cro-2492' },
    [TENANT_IT]: { desktop: 'cro-2489', mobile: 'cro-2490' },
    [TENANT_CH]: { desktop: 'cro-2493', mobile: 'cro-2494' },
  }

export const AFTER_QUERY_SUGGEST_LAYER: TenantBasedExperiment = {
  [TENANT_COM]: { desktop: 'cro-2734', mobile: 'cro-2735' },
  [TENANT_AT]: { desktop: 'cro-2737', mobile: 'cro-2736' },
  [TENANT_FR]: { desktop: 'cro-2745', mobile: 'cro-2744' },
  [TENANT_IT]: { desktop: 'cro-2742', mobile: 'cro-2743' },
  [TENANT_CH]: { desktop: 'cro-2746', mobile: 'cro-2747' },
}

export const CARTINTERMEDIATE_ERX_BANNER: TenantBasedExperiment = {
  [TENANT_COM]: { desktop: 'cro-2940', mobile: 'cro-2941' },
}

export const CART_FREE_GIFT_PROMOTION: TenantBasedExperiment = {
  [TENANT_COM]: 'cro-3312',
  [TENANT_AT]: 'cro-3313',
  [TENANT_FR]: 'cro-3316',
  [TENANT_IT]: 'cro-3315',
  [TENANT_CH]: 'cro-3314',
}

export const EXIT_INTENT_POPUP_EXPERIMENT: TenantBasedExperiment = {
  [TENANT_COM]: { desktop: 'cro-3102', mobile: 'cro-3103' },
  [TENANT_AT]: { desktop: 'cro-3105', mobile: 'cro-3104' },
  [TENANT_FR]: { desktop: 'cro-3109', mobile: 'cro-3108' },
  [TENANT_IT]: { desktop: 'cro-3106', mobile: 'cro-3107' },
  [TENANT_CH]: { desktop: 'cro-3110', mobile: 'cro-3111' },
}

export const ALL_PRODUCTS_FROM_SELLER_EXPERIMENT: TenantBasedExperiment = {
  [TENANT_COM]: 'cro-3214',
  [TENANT_AT]: 'cro-3215',
  [TENANT_IT]: 'cro-3609',
}

export const NFC_LANDING_PAGE_BANNERS: TenantBasedExperiment = {
  [TENANT_COM]: { desktop: 'wserx-199', mobile: 'wserx-199' },
}

export const CHANGE_CTA_TEXT_NAME_BE: TenantBasedExperiment = {
  [TENANT_BE]: 'cro-3358',
}

export const NEWSLETTER_MODAL_CH: TenantBasedExperiment = {
  [TENANT_CH]: 'cro-3685',
}

export const REMOVE_PACKSIZE_AND_CTA_FROM_SERP: TenantBasedExperiment = {
  [TENANT_COM]: 'cro-3791',
  [TENANT_AT]: 'cro-3792',
  [TENANT_IT]: 'cro-3793',
  [TENANT_FR]: 'cro-3794',
  [TENANT_CH]: 'cro-3795',
  [TENANT_BE]: 'cro-3796',
}

export const OWN_BRANDS_CSW_HOME_AT_EXPERIMENT: TenantBasedExperiment = {
  [TENANT_AT]: 'cro-3752',
}
