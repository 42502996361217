// @ts-nocheck
import { Context } from 'koa'
import { stringify } from 'qs'

import { FILE_UPLOAD_TIMEOUT } from 'shared/consts'
import {
  SUBJECT_TYPE_ORDER_MESSAGING,
  THREAD_ENTITY_TYPE,
  VISIBLE_THREADS_COUNT,
} from 'shared/consts/marketplace'
import {
  AllowedOrdersForRefundResponseV1,
  MessagingThread,
  MessagingThreadsList,
  MessagingThreadsListV1,
  MessagingThreadV1,
  NewMessageResponse,
  NewMessageResponseV1,
  NewThreadBody,
  NewThreadBodyV1,
  NewThreadMessageBody,
  NewThreadMessageBodyV1,
  NewThreadResponse,
  NewThreadResponseV1,
  OrderLineItemsResponseItemV1,
  ThreadAttachmentResponse,
  ThreadSubjectsResponse,
  ThreadSubjectsResponseV1,
} from 'shared/services/api/models'
import { OrderLineItemsResponse } from 'shared/services/api/models/orders/orderLineItemsResponse'
import { OrdersForRefundResponse } from 'shared/services/api/models/orders/ordersForRefundResponse'
import assert from 'shared/utils/assert'
import { isEmpty } from 'shared/utils/objectUtils'
import { createTenantPath } from 'shared/utils/routing'

import BackendAPIService from './BackendAPIService'

interface ThreadsListURLQueryParams {
  entityType: string
  limit?: string | number
  pageToken?: string
}

interface FetchThreadsListProps {
  pageToken: string
  limit: number
}

interface FetchMessageAttachmentProps {
  threadId: string
  attachmentId: string
}

interface IMiraklService {
  createNewMessage(
    ctx: Context,
    newMessageBody: NewThreadMessageBodyV1
  ): Promise<NewMessageResponse>

  createNewThread(
    ctx: Context,
    orderId: string,
    newMessageBody: NewThreadBodyV1
  ): Promise<NewThreadResponse>

  fetchThreadById(
    ctx: Context,
    { threadId }: { threadId: string }
  ): Promise<MessagingThread>

  fetchThreads(
    ctx: Context,
    params: FetchThreadsListProps
  ): Promise<MessagingThreadsList>

  fetchAggregatedThreadsList(
    ctx: Context,
    params: FetchThreadsListProps
  ): Promise<MessagingThreadsList>

  fetchThreadSubjects(): Promise<ThreadSubjectsResponse>

  fetchOrdersByCommercialIds(
    ctx: Context,
    { commercialIds }: { commercialIds: string[] }
  ): Promise<OrdersForRefundResponse>

  fetchThreadSubjectsByType({
    subjectType,
    language,
  }: {
    subjectType: string
  }): Promise<ThreadSubjectsResponse>

  fetchMessageAttachmentById(
    ctx: Context,
    { threadId, attachmentId }: FetchMessageAttachmentProps
  ): Promise<ThreadAttachmentResponse>

  markThreadAsRead({ threadId }: { threadId: string }): Promise<void>
}

class MiraklService extends BackendAPIService implements IMiraklService {
  private readonly basePath: string

  constructor(params) {
    super({
      apiVersion: 'v3',
      timeout: FILE_UPLOAD_TIMEOUT,
      ...params,
    })
    const { tenant, coreShopEnvironment } = params
    const tenantPath = createTenantPath(tenant, coreShopEnvironment)
    this.basePath = `/mirakl/${this.apiVersion}/${tenantPath}`
  }

  /**
   * @method getThreadsListURL fetch threads list endpoint URL.
   * @param {object} queryParams - request parameters.
   * @param {string} [queryParams.pageToken] - Next or previous page token.
   * @constant {string} queryParams.entityType - Type of threads to fetch.
   */
  getThreadsListURL(queryParams: ThreadsListURLQueryParams): string {
    return `${this.basePath}/threads${
      isEmpty<ThreadsListURLQueryParams>(queryParams)
        ? ''
        : `?${stringify(queryParams)}`
    }`
  }

  /**
   * @function Returns thread reasons URL.
   * @method getThreadByIdURL - returns thread by id endpoint URL.
   * @param {object} params - URL params
   * @param {string} params.threadId - thread to fetch
   */
  getThreadByIdURL({ threadId }: { threadId: string }): string {
    return `${this.basePath}/threads/${threadId}`
  }

  /**
   * @function Returns thread marked as read URL.
   * @method markThreadAsRead - mark thread as read endpoint URL.
   * @param {object} params - URL params
   * @param {string} params.threadId - thread to save
   */
  markThreadAsReadURL({ threadId }: { threadId: string }): string {
    return `${this.basePath}/threads/markAsRead/${threadId}`
  }

  /**
   * @method getNewMessageURL
   * @param {object} requestParams - request parameters
   * @param {string} requestParams.threadId - thread ID where message will be posted
   * @returns {string} url
   */
  getNewMessageURL({ threadId }: { threadId: string }): string {
    return this.getThreadByIdURL({ threadId })
  }

  getNewThreadURL({ orderId }: { orderId: string }): string {
    return `${this.basePath}/threads/${orderId}/new`
  }

  /**
   * @method getThreadsSubjectsURL thread reasons URL.
   */
  getThreadsSubjectsURL(): string {
    return `${this.basePath}/reasons`
  }

  /**
   * @method getThreadsSubjectsByTypeURL - Returns thread resons by type URL.
   * @param {object} params - request parameters.
   * @param {string} [params.subjectType=SUBJECT_TYPE_ORDER_MESSAGING] - will filter subjects by given type
   */
  getThreadsSubjectsByTypeURL(
    params: {
      subjectType: string
      language: string
    } = {
      subjectType: SUBJECT_TYPE_ORDER_MESSAGING,
      language: 'de',
    }
  ): string {
    params.language ??= 'de'
    const { subjectType, language } = params
    return `${this.basePath}/reasons/${subjectType}?language=${language}`
  }

  /**
   * @method getOrderLineItemsByCommercialIdsURL - Returns order items by commercialIds URL.
   * @param {string[]} [params.commercialIds] - will filter orders by commercial IDs provided
   * @param params
   */
  getOrderLineItemsByCommercialIdsURL(params: {
    commercialIds: string[]
  }): string {
    const { commercialIds } = params
    return `${
      this.basePath
    }/order/items?commercialOrderIds=${commercialIds.join(',')}`
  }

  /**
   * @method getOrderLineItemsByCommercialIdsURL - Returns order items by commercialIds URL.
   * @param {string[]} [params.commercialIds] - will filter orders by commercial IDs provided
   * @param params
   */
  getOrdersByCommercialIdsURL(params: { commercialIds: string[] }): string {
    const { commercialIds } = params
    return `${this.basePath}/orders?commercialOrderIds=${commercialIds.join(
      ','
    )}`
  }

  /**
   * @method getMessageAttachmentByIdURL - Returns attachment by id URL.
   * @param {object} params - request parameters.
   * @param {string} params.threadId - id of the thread with attachmentts
   * @param {string} params.attachmentId - attachment id
   */
  getMessageAttachmentByIdURL({
    threadId,
    attachmentId,
  }: {
    threadId: string
    attachmentId: string
  }): string {
    return `${this.basePath}/download/${threadId}/${attachmentId}`
  }

  async fetchThreads(
    ctx: Context,
    { pageToken, limit = VISIBLE_THREADS_COUNT }: FetchThreadsListProps
  ): Promise<MessagingThreadsList> {
    this.logger.debug(`Mirakl service: fetching threads list!`, 'dataFetching')

    const requestParams: ThreadsListURLQueryParams = {
      entityType: THREAD_ENTITY_TYPE,
      limit,
    }
    if (pageToken) {
      // @ts-ignore
      requestParams.pageToken = pageToken
    }
    const { data } = await this.get<MessagingThreadsListV1>(
      // @ts-ignore
      this.getThreadsListURL(requestParams),
      null,
      ctx
    )
    return new MessagingThreadsList(data)
  }

  async fetchThreadById(
    ctx: Context,
    { threadId }: { threadId: string }
  ): Promise<MessagingThread> {
    assert(threadId, 'MiraklService.fetchThreadById: please provide "threadId"')
    this.logger.debug(`Mirakl service: fetching threads list!`, 'dataFetching')
    const { data } = await this.get<MessagingThreadV1>(
      this.getThreadByIdURL({ threadId }),
      null,
      ctx
    )

    return new MessagingThread(data)
  }

  async markThreadAsRead({ threadId }: { threadId: string }): Promise<void> {
    assert(
      threadId,
      'MiraklService.markThreadAsRead: please provide "threadId"'
    )
    this.logger.debug(`Mirakl service: marking thread as read!`, 'dataFetching')

    await this.post<NewThreadBody>(
      this.markThreadAsReadURL({ threadId }),
      {},
      null
    )

    return
  }

  async createNewMessage(
    ctx: Context,
    newMessageBody: NewThreadMessageBodyV1
  ): Promise<NewMessageResponse> {
    const { message, threadId, to } = newMessageBody
    assert(
      threadId,
      'MiraklService.createNewMessage: please provide "threadId"'
    )
    assert(message, 'MiraklService.createNewMessage: please provide "message"')
    assert(to, 'MiraklService.createNewMessage: please provide recipients "to"')

    const payload: NewThreadMessageBody = new NewThreadMessageBody(
      newMessageBody
    )
    const { data } = await this.post<
      NewThreadMessageBody,
      NewMessageResponseV1
    >(this.getNewMessageURL({ threadId }), payload, null, ctx)
    return new NewMessageResponse(data)
  }

  async createNewThread(
    ctx: Context,
    orderId: string,
    newMessageBody: NewThreadBodyV1
  ): Promise<NewThreadResponse> {
    const { message } = newMessageBody
    assert(orderId, 'MiraklService.createNewThread: please provide "orderId"')
    assert(message, 'MiraklService.createNewThread: please provide "message"')

    const payload: NewThreadBody = new NewThreadBody(newMessageBody)

    const { data } = await this.post<NewThreadBody, NewThreadResponseV1>(
      this.getNewThreadURL({ orderId }),
      payload,
      null,
      ctx
    )

    return new NewThreadResponse(data)
  }

  async fetchThreadSubjects(): Promise<ThreadSubjectsResponse> {
    this.logger.debug(
      `Mirakl service: fetching thread subjects for  list!`,
      'dataFetching'
    )

    const { data } = await this.get<ThreadSubjectsResponseV1>(
      this.getThreadsSubjectsURL()
    )

    return new ThreadSubjectsResponse(data)
  }

  async fetchThreadSubjectsByType({
    subjectType,
    language,
  }: {
    subjectType: string
    language: string
  }): Promise<ThreadSubjectsResponse> {
    assert(
      subjectType,
      'MiraklService.fetchThreadSubjectsByType: please provide "subjectType"'
    )
    this.logger.debug(
      `Mirakl service: fetching thread subjects by type list!`,
      'dataFetching'
    )

    const { data } = await this.get<ThreadSubjectsResponseV1>(
      this.getThreadsSubjectsByTypeURL({ subjectType, language })
    )

    return new ThreadSubjectsResponse(data)
  }

  async fetchOrderLineItemsByCommercialIds(
    ctx: Context,
    {
      commercialIds,
    }: {
      commercialIds: string[]
    }
  ): Promise<OrderLineItemsResponse> {
    assert(
      commercialIds,
      'MiraklService.fetchOrderLineItemsByCommercialIds: please provide "commercialId"'
    )
    this.logger.debug(
      `Mirakl service: fetching order items by commercialId!`,
      'dataFetching'
    )

    const { data } = await this.get<OrderLineItemsResponseItemV1[]>(
      this.getOrderLineItemsByCommercialIdsURL({ commercialIds }),
      null,
      ctx
    )

    return new OrderLineItemsResponse(data)
  }

  async fetchOrdersByCommercialIds(
    ctx: Context,
    {
      commercialIds,
    }: {
      commercialIds: string[]
    }
  ): Promise<OrdersForRefundResponse> {
    assert(
      commercialIds,
      'MiraklService.fetchOrdersByCommercialIds: please provide "commercialId"'
    )
    this.logger.debug(
      `Mirakl service: fetching orders by commercialId!`,
      'dataFetching'
    )

    const { data } = await this.get<AllowedOrdersForRefundResponseV1[]>(
      this.getOrdersByCommercialIdsURL({ commercialIds }),
      null,
      ctx
    )

    return new OrdersForRefundResponse(data)
  }

  async fetchAggregatedThreadsList(
    ctx: Context,
    { pageToken, limit = VISIBLE_THREADS_COUNT }: FetchThreadsListProps
  ): Promise<MessagingThreadsList> {
    const fetchThreadsCall = this.fetchThreads(ctx, {
      pageToken,
      limit,
    })
    const fetchThreadSubjectsCall = this.fetchThreadSubjectsByType({
      subjectType: SUBJECT_TYPE_ORDER_MESSAGING,
    })

    const [threadsData, threadSubjects] = await Promise.all([
      fetchThreadsCall,
      fetchThreadSubjectsCall,
    ])

    return new MessagingThreadsList(threadsData, threadSubjects)
  }

  async fetchMessageAttachmentById(
    ctx: Context,
    { threadId, attachmentId }: FetchMessageAttachmentProps
  ) {
    assert(
      threadId,
      'MiraklService.fetchMessageAttchmentById: please provide "threadId" for attachment'
    )

    assert(
      attachmentId,
      'MiraklService.fetchMessageAttchmentById: please provide "attachmentId"'
    )

    this.logger.debug(
      'MiraklService: fetching message attachment by id',
      'dataFetching'
    )

    const { data } = await this.get(
      this.getMessageAttachmentByIdURL({ threadId, attachmentId }),
      null,
      ctx
    )

    return data
  }
}

export default MiraklService
