import {
  REBRANDING_COM_TOGGLE,
  REBRANDING_IT_TOGGLE,
} from 'shared/experiments/consts/names'
import { EXPERIMENT_VARIATION } from 'shared/experiments/consts/variants'
import type { Experiment } from 'types/shopConfig'

export const rebrandingComExperiment: Experiment = {
  name: REBRANDING_COM_TOGGLE,
  isEnabled: true,
  variables: { f: 1 },
  variant: EXPERIMENT_VARIATION.V1,
}

export const rebrandingItExperiment: Experiment = {
  name: REBRANDING_IT_TOGGLE,
  isEnabled: true,
  variant: EXPERIMENT_VARIATION.V1,
  variables: { f: 1 },
}
