import {
  SEARCH_BOX_CLEAN_HISTORY_CLICK,
  SEARCH_BOX_QUERY_HISTORY_CLICK,
  SEARCH_BOX_QUERY_SUGGESTION_CLICK,
  SEARCH_BOX_SUGGESTED_BRAND_CLICK,
  SEARCH_BOX_SUGGESTED_CATEGORY_CLICK,
  SEARCH_BOX_SUGGESTED_PRODUCT_CLICK,
  SERP_PRE_QUERY_SUGGESTIONS_PER_TENANT_EXPERIMENT,
} from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperimentOnClient,
} from 'shared/experiments/utils/experiments'
import { getExperimentNamePerTenant } from 'shared/experiments/utils/tenantBasedExperimentsUtils'
import trackOptimizelyEvent from 'shared/experiments/utils/trackOptimizelyEvent'
import store from 'shared/store'
import { EXCLUDED_PAGE_TYPES } from 'views/components/organisms/PreQuerySuggestions/consts'

export default () => {
  const { pageType } =
    store.getState()?.publicRuntimeConfig.pageProperties || {}

  const { tenant, deviceClass } =
    store.getState()?.publicRuntimeConfig.publicConfig || {}

  const serpPreQuerySuggestionExperimentName = getExperimentNamePerTenant(
    SERP_PRE_QUERY_SUGGESTIONS_PER_TENANT_EXPERIMENT,
    tenant,
    deviceClass
  )

  const serpPreQuerySuggestionExperiment = getExperimentOnClient(
    serpPreQuerySuggestionExperimentName
  )

  if (
    !serpPreQuerySuggestionExperiment.isEnabled ||
    EXCLUDED_PAGE_TYPES.includes(pageType)
  )
    return

  const trackEvents = (event: Event) => {
    const target = <HTMLElement>event.target

    if (target.closest('[data-clientside-hook~="SearchBoxListItemLink_PQ"]')) {
      trackOptimizelyEvent(SEARCH_BOX_SUGGESTED_PRODUCT_CLICK)
    }
    if (target.closest('[data-clientside-hook~="SearchBoxListSuggestLink"]')) {
      trackOptimizelyEvent(SEARCH_BOX_QUERY_SUGGESTION_CLICK)
    }
    if (target.closest('[data-qa-id="form-searchistory-delete"]')) {
      trackOptimizelyEvent(SEARCH_BOX_CLEAN_HISTORY_CLICK)
    }
    if (target.closest('[data-clientside-hook~="SearchBoxListHistoryLink"]')) {
      trackOptimizelyEvent(SEARCH_BOX_QUERY_HISTORY_CLICK)
    }
    if (
      target.closest('[data-clientside-hook~="SearchBoxListSuggestBrandLink"]')
    ) {
      trackOptimizelyEvent(SEARCH_BOX_SUGGESTED_BRAND_CLICK)
    }
    if (
      target.closest(
        '[data-clientside-hook~="SearchBoxListSuggestCategoryLink"]'
      )
    ) {
      trackOptimizelyEvent(SEARCH_BOX_SUGGESTED_CATEGORY_CLICK)
    }
  }
  const preQueryWrapper = document.querySelector(
    '.o-PreQuerySuggestLayer__wrapper'
  )
  const mobilePreQueryWrapper = document.querySelector(
    '[data-clientside-hook~="SearchBoxMobileContainer"]'
  )

  preQueryWrapper?.addEventListener('click', trackEvents)
  mobilePreQueryWrapper?.addEventListener('click', trackEvents)

  document.addEventListener('click', async (e: MouseEvent) => {
    const target = e.target as HTMLElement

    if (
      target.closest('[data-clientside-hook="h-SearchBox__trigger--mobile"]') ||
      target.closest('.o-SearchBox')
    ) {
      if (
        sessionStorage.getItem(serpPreQuerySuggestionExperimentName) === 'true'
      ) {
        return
      }
      sessionStorage.setItem(serpPreQuerySuggestionExperimentName, 'true')

      await activateExperiment(serpPreQuerySuggestionExperimentName)
    }
  })
}
