import {
  CART_MAX_DOSAGE,
  CPH_MAX_DOSAGE_TOGGLE,
} from 'shared/experiments/consts'

export const createRequestHeaders = ({
  authorization,
  contentType = 'application/json',
}) => ({
  headers: {
    'Content-Type': contentType,
    'Authorization': authorization,
  },
})

/**
 * several experiment features require specific headers to be
 * present in the API request that Fock sends to the backend service;
 * this function looks at the provided `experiments` and provision headers
 * as required by those experiments
 *
 * @param {Record<string, string | string[] | undefined>} [headers] - the standard request header object
 * @param {import('types/shopConfig').Experiment[]} [experiments] - the experiments provisioned during clientAPI initiation
 * @return {void}
 * @NOTE please feel free to add your team's logic here if/when needed, this function is open-ended
 */
export const addHeadersByExperiments = (headers, experiments) => {
  if (!headers || !experiments) {
    return
  }

  const applyCphMaxDosageToggle = experiments.find(
    ({ name }) => name === CPH_MAX_DOSAGE_TOGGLE
  )

  if (applyCphMaxDosageToggle) {
    headers['x-cph-maxdosage'] = applyCphMaxDosageToggle.variant
  }

  const applyCphMaxDosageExperiment = experiments.find(
    ({ name }) => name === CART_MAX_DOSAGE
  )

  if (applyCphMaxDosageExperiment) {
    headers['x-cph-maxdosage-exp'] = applyCphMaxDosageExperiment.variant
  }

  // @NOTE this function is open-ended, please feel free to add more headers
  // associated with experimental / canary-release features, for example:
  //
  // const myTeamsExampleExperiment = experiments.find(
  //   ({ name }) => name === 'MY_TEAMS_OPTIMIZELY_EXPERIMENT_NAME'
  // )
  // headers['x-my-team-experiment-header-that-fock-should-send-to-the-service'] =
  //   myTeamsExampleExperiment?.variant === EXPERIMENT_VARIATION.VARIATION_1
  //
}
