export const AD_BANNER_CLIENTSIDE_HOOK = 'retailMedia_slot'
export const AD_BANNER_CLOSE_CLIENTSIDE_HOOK = 'bannerClose'
export const AD_BANNER_LEGAL_BUTTON_CLIENTSIDE_HOOK = 'asLegalButton'
export const AD_BANNER_CAMPAIGN_CODE_SEARCH_PARAM = 'campaigncode'
export const AD_BANNER_STATUS_DATA_ATTR = 'data-as-status'
export const AD_BANNER_TAGS_DATA_ATTR = 'data-as-tags'
export const AD_BANNER_CONTENT_DATA_ATTR = 'data-as-content'
export const AD_BANNER_CONTAINER_DATA_ATTR = 'data-as-container'
export const AD_BANNER_WIDTH_DATA_ATTR = 'data-as-width'
export const AD_BANNER_HEIGHT_DATA_ATTR = 'data-as-height'
export const AD_BANNER_AUTO_LOAD_DATA_ATTR = 'data-auto-load'
export const AD_BANNER_STORAGE_KEY = 'ad_banner_flag'
export const AD_BANNER_FLIGHT_ID_DATA_ATTR = 'data-as-flight-id'
export const AD_BANNER_TOP_BANNER_DISPLAY_DATA_ATTR = 'data-as-top-banner'
export const AD_BANNER_EXCLUDE_PAGE_SPECIFIC_CONTEXT_ATTR =
  'data-as-exclude-page-specific-context'
export const AD_BANNER_LEGAL_BUTTON_TEXT_CLASS =
  'as-placeholder__legalButton-text'
export const AD_BANNER_LEGAL_BUTTON_LABEL_CLASS =
  'as-placeholder__legalButton-label'
export const AD_BANNER_LEGAL_BUTTON_INNER_TEXT_CLASS =
  'as-placeholder__legalButton-text--inner'
