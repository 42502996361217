import { NEW_CSW } from 'shared/experiments/consts'
import { trackCswSlider } from 'shared/experiments/consts/trackCswSlider'
import {
  activateExperiment,
  getExperimentOnClient,
} from 'shared/experiments/utils/experiments'

export default () => {
  const { isEnabled } = getExperimentOnClient(NEW_CSW)

  if (!isEnabled) {
    return
  }

  void activateExperiment(NEW_CSW)

  trackCswSlider({
    shouldTrackArrowClick: true,
    shouldTrackShowAllClick: true,
    shouldTrackShowMoreClick: true,
    shouldTrackWithWidgetIds: true,
  })
}
