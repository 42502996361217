import {
  TENANT_AT,
  TENANT_BE,
  TENANT_CH,
  TENANT_COM,
  TENANT_FR,
  TENANT_IT,
} from 'shared/consts/tenants'
import {
  rebrandingComExperiment,
  rebrandingItExperiment,
} from 'shared/experiments/consts/experimentsToInject'

import { RELEASE_SWITCH_TOGGLE, RELEASE_SWITCH_TOGGLE_AT } from './names'
import type { ExperimentName } from './types'

export const RELEASE_SWITCH_TOGGLES: Record<string, ExperimentName> = {
  [TENANT_COM]: RELEASE_SWITCH_TOGGLE,
  [TENANT_AT]: RELEASE_SWITCH_TOGGLE_AT,
  default: RELEASE_SWITCH_TOGGLE,
}

/**
 * List of experiments that are manually activated.
 *
 * Add the experiment name here if the experiment is something like AA test or
 * there is no `<Experiment name=... />` part
 */
export const MANUALLY_ACTIVATED: ExperimentName[] = [
  RELEASE_SWITCH_TOGGLE,
  RELEASE_SWITCH_TOGGLE_AT,
]

/**
 * List of experiments that are manually injected.
 * this is used for the experiments that are tested and activated for some tenants
 * but still needs to be ab tested in other tenants so we need the experiment code
 */
export const getManuallyInjectedExperiments = (tenant: string | undefined) => {
  switch (tenant) {
    case TENANT_IT:
      return [rebrandingItExperiment]
    case TENANT_AT:
      return [rebrandingComExperiment]
    case TENANT_BE:
    case TENANT_FR:
    case TENANT_CH:
      return [rebrandingComExperiment]
    case TENANT_COM:
      return [rebrandingComExperiment]
    default:
      return []
  }
}
