import { parseUrl, stringify } from 'query-string'

import { subscribe } from 'shared/store'
import { CHARGE_SEARCH_PARAMS } from 'shared/store/ducks/events'
import algoliaUserService from 'views/assets/scripts/services/storage/AlgoliaUserService'
import { extractSearchParams } from 'views/utils/extractSearchParams'

/**
 * chargeUrlSearchParams
 * ========
 *
 * Script adds to links and forms with the hook a special set of search parameters,
 * which `SEARCH_PARAMS` defines.
 *
 * Script updates links' href and forms' action attributes.
 * Script runs on page load and on every SEARCH_PARAMS_CHARGE event emitted.
 *
 * @see app/views/utils/extractSearchParams.js
 *
 * @example
 * <ButtonPrimary
 *    href="/add-to-cart.htm?initialParam=1&anotherParam=false"  <-- %INITIAL_QUERY_STRING
 *    data-clientside-hook="chargeUrlSearchParams"
 * >Press Me</ButtonPrimary>
 *
 * %INITIAL_QUERY_STRING: Initial params will be kept, the special search params
 *                        from the `window.location` will be added
 *
 * @returns {HTMLLinkElement[]} Array of processed links
 */

class SearchParamsUrlCharger {
  static HOOK_NAME = 'chargeUrlSearchParams'

  constructor(container) {
    this.container = container
    this.searchParams = extractSearchParams(window.location.search)
    this.searchParams.userToken = algoliaUserService.getToken()
  }

  getAttachedElements = () => {
    return [
      ...(this.container || document).querySelectorAll(
        `[data-clientside-hook~="${SearchParamsUrlCharger.HOOK_NAME}"]`
      ),
    ]
  }

  createHiddenField = (name, value) => {
    /** @var {HTMLInputElement} field */
    const field = document.createElement('input')

    field.setAttribute('type', 'hidden')
    field.setAttribute('name', name)
    field.value = value

    return field
  }

  /**
   * @param {HTMLFormElement} form
   * @param {object} searchParams
   * @return {HTMLFormElement}
   */
  injectHiddenFieldsToForm = (form, searchParams) => {
    Object.entries(searchParams).forEach(([name, value]) => {
      form.appendChild(this.createHiddenField(name, value))
    })

    return form
  }

  processLinkElement = element => {
    const hrefAttribute = element.getAttribute('href')

    if (hrefAttribute.startsWith('#')) {
      // skip anchor links
      return element
    }

    const { url, query } = parseUrl(hrefAttribute)
    const mergedParams = { ...this.searchParams, ...query }

    element.setAttribute('href', `${url}?${stringify(mergedParams)}`)

    return element
  }

  processFormElement = element => {
    const { query: actionParams } = parseUrl(element.getAttribute('action'))
    const mergedParams = { ...this.searchParams, ...actionParams }

    return this.injectHiddenFieldsToForm(element, mergedParams)
  }

  reduceAttachedElements = (resultArr, element) => {
    if (element.tagName.toUpperCase() === 'A') {
      resultArr.push(this.processLinkElement(element))
    } else if (element.tagName.toUpperCase() === 'FORM') {
      resultArr.push(this.processFormElement(element))
    }

    return resultArr
  }

  run() {
    return this.getAttachedElements().reduce(this.reduceAttachedElements, [])
  }
}

export default () => {
  // Run when CrossSell widget is loaded
  subscribe.after.once(CHARGE_SEARCH_PARAMS, ({ selector }) => {
    const container = document.querySelector(selector)
    if (container) {
      new SearchParamsUrlCharger(container).run()
    }
  })

  // Run on prerendered layout
  return new SearchParamsUrlCharger().run()
}
