export const GTM_EVENT_TYPE_CLICK = 'click'
export const GTM_EVENT_CLICK_PRODUCT_TITLE = 'click on product title'
export const GTM_EVENT_CLICK_PRODUCT_BUTTON = 'click on product button'
export const GTM_EVENT_CLICK_PRODUCT_IMAGE = 'click on product image'
export const GTM_EVENT_CLICK_PRODUCT = 'click on product list item'

export enum GtmQueryParameter {
  LOGIN = 'login',
  REGISTRATION = 'registration',
}
