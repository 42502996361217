import store from 'shared/store'
import { SELLER_MODAL_CONTAINER_ID } from 'views/components/organisms/SellerModal/names'
import loadCatalogs from 'views/containers/browser/utils/loadCatalogs'

import { OPEN_SELLER_MODAL } from './clientsideHook'

const handleButtonClick = async ({ productId, sellerId, sellerModal }) => {
  const {
    publicConfig: { locale },
  } = store.getPublicRuntimeConfig()

  const i18n = await loadCatalogs(locale)

  try {
    const sellerModalContainer = await import(
      /* webpackChunkName: "SellerModalContainerWithRender" */
      './SellerModalContainerWithRender'
    )

    sellerModalContainer.default({
      productId,
      container: sellerModal,
      sellerId,
      i18n,
    })
  } catch (err) {
    throw new Error(err)
  }
}

export default () => {
  const sellerModalButtons = document.querySelectorAll(
    `[data-clientside-hook*="${OPEN_SELLER_MODAL}"]`
  )
  const sellerModal = document.getElementById(SELLER_MODAL_CONTAINER_ID)

  if (!sellerModalButtons.length || !sellerModal) {
    return
  }

  sellerModalButtons.forEach(button => {
    const sellerId = button
      .getAttribute('data-clientside-hook')
      .match(new RegExp(`${OPEN_SELLER_MODAL}--(.+)$`))[1]

    if (!sellerId) {
      return
    }
    const productId = button.getAttribute('data-product-id')

    button.addEventListener('click', () =>
      handleButtonClick({ productId, sellerId, sellerModal })
    )
  })
}
