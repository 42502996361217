import { PRESCRIPTION_TYPES } from 'shared/consts/productTemplates'

/**
 * PrescriptionTypes definitions and as a collection
 */
export const PRESCRIPTION_TYPE_NO_PRESCRIPTION = 'noPrescription'
export const PRESCRIPTION_TYPE_INSURANCE = 'insurancePrescription'
export const PRESCRIPTION_TYPE_FREE = 'freePrescription'
export const PRESCRIPTION_TYPE_PRIVAT = 'privatePrescription'
export const PRESCRIPTION_TYPE_ODS = 'odsSection'
export const PRESCRIPTION_TYPE_ERX = 'electronicPrescription'

export type PrescriptionType =
  typeof PRESCRIPTION_TYPES[keyof typeof PRESCRIPTION_TYPES]
