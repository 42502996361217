/** Script is responsible for the suggestions in the header search box */
import algoliasearch from 'algoliasearch/lite'

import {
  ANALYTICS_TAG_SUGGESTIONS,
  DEFAULT_PARAMS,
  HITS_PER_PAGE_SEARCH_BOX,
  SEARCHABLE_FILTERS,
} from 'shared/consts/algolia'
import isAlgoliaAbTestEnabled from 'shared/experiments/browser/algoliaSplit/isAlgoliaAbTestEnabled'
import { isMarketplaceEnabledForTenant } from 'shared/experiments/utils/featureFlags'
import { validate as validateEan } from 'shared/services/api/helpers/eanValidator'
import { validate as validatePzn } from 'shared/services/api/helpers/pznValidator'
import store from 'shared/store'
import getDevicePlatform from 'shared/utils/algolia/getDevicePlatform'
import { getObjectIdsSearchState } from 'shared/utils/algolia/searchState'
import assert from 'shared/utils/assert'
import { stripLanguageFromPath } from 'shared/utils/url'

import BackendAPIService from './BackendAPIService'

class ClientAPIService extends BackendAPIService {
  constructor(props) {
    super(props)

    const { searchConfig } = props

    assert(searchConfig, 'ClientAPIService: no "searchConfig" option provided')

    this.searchConfig = searchConfig
  }

  async searchAlgolia(
    query,
    { userToken, deviceClass = null, expa = null, ruleContexts = [] },
    shouldFetchQuerySuggestions = false
  ) {
    assert(query, 'ClientAPIService.searchAlgolia: please provide a "query"')
    this.logger.debug(
      `Fetching Search-Autosuggestions (Algolia) for "${query}"`,
      'dataFetchingAll'
    )

    const {
      algolia: { appId, apiKey, indexName, mpIndexName },
      suggest: {
        appId: suggestAppId,
        apiKey: suggestApiKey,
        indexName: suggestIndexName,
      },
    } = this.searchConfig

    const {
      pageProperties: { experiments },
      publicConfig: { tenant },
    } = store.getPublicRuntimeConfig()

    const toggleSwitchIndex = isMarketplaceEnabledForTenant(experiments, tenant)
      ? mpIndexName
      : indexName

    const ruleContextsList = []
    if (expa) {
      ruleContextsList.push(expa)
    }
    if (ruleContexts?.length) {
      ruleContextsList.push(...ruleContexts)
    }

    const params = {
      ...DEFAULT_PARAMS,
      query,
      hitsPerPage: HITS_PER_PAGE_SEARCH_BOX,
      clickAnalytics: true,
      getRankingInfo: true,
      analyticsTags: [
        ANALYTICS_TAG_SUGGESTIONS,
        getDevicePlatform(deviceClass),
      ],
      ruleContexts: ruleContextsList,
      enableABTest: isAlgoliaAbTestEnabled(experiments),
    }

    const { valid: validPzn } = validatePzn(query)
    const { valid: validEan } = validateEan(query)
    if (validPzn || validEan) {
      params.filters = SEARCHABLE_FILTERS
    }

    const modifyParamsIfPznQuery = getObjectIdsSearchState(params)
    params.filters = modifyParamsIfPznQuery.filters
    params.query = modifyParamsIfPznQuery.query

    const searchClientConfig = {}
    if (userToken) {
      params.userToken = userToken
      params.analytics = true
      searchClientConfig.headers = {}
      searchClientConfig.headers['X-Algolia-UserToken'] = userToken
    }

    const searchClient = shouldFetchQuerySuggestions
      ? algoliasearch(suggestAppId, suggestApiKey, searchClientConfig)
      : algoliasearch(appId, apiKey, searchClientConfig)

    const index = shouldFetchQuerySuggestions
      ? searchClient.initIndex(suggestIndexName)
      : searchClient.initIndex(toggleSwitchIndex)

    const searchParams = shouldFetchQuerySuggestions
      ? { hitsPerPage: 3 }
      : params
    const response = await index.search(query, searchParams)

    this.logger.debug(
      `Search-Autosuggestions (Algolia) for "${query}" fetched!`,
      'dataFetchingAll'
    )

    return response
  }

  getLegalTextPath() {
    return `/cacheable/${this.language}/content/${this.apiVersion}/${this.tenantPath}/snippetsFooter`
  }

  async fetchLegalText() {
    this.logger.debug('Fetching LegalText', 'dataFetchingAll')
    const params = {
      contentsFilter: 'headline:legalText',
      fields: 'id title contents { headline content }',
    }
    const { status, data } = await this.get(this.getLegalTextPath(), { params })
    this.logger.debug('LegalText fetched!', 'dataFetchingAll')

    return {
      status,
      data: data.contents[0].content,
    }
  }

  getOffCanvasCategoriesByPath(categoryPath) {
    categoryPath = stripLanguageFromPath(categoryPath, this.language)
    const pathWithoutSlashes = categoryPath.replaceAll('/', '')
    return `cacheable/${this.language}/content/v3/${this.tenantPath}/nodes/${pathWithoutSlashes}`
  }

  getOffCanvasCategoriesById(id) {
    return `cacheable/${this.language}/content/v3/${this.tenantPath}/nodes-by-code/${id}`
  }

  getConvertedResponse(item) {
    const subcategories =
      item?.down?.length > 0
        ? item.down.map(cat => this.getConvertedResponse(cat))
        : []

    const mappedData = {
      'id': item?.code,
      'name': item?.caption,
      'canonicalUrl': this.buildCanonicalUrl(item),
      'seoName': item?.path,
      subcategories,
      'hasSubcategories': item?.child_count > 0,
    }

    return mappedData
  }

  /**
   * builds a url link for node item
   * on multi-lang (e.g. CH) shopurl ends with `/`
   * in this case func handles removing `/` to avoid double `//`
   * https://jira.shop-apotheke.com/browse/WSAWA-3701
   * @param item {undefined | {path?: string}}
   * @return {string|null}
   */
  buildCanonicalUrl(item) {
    const path = item?.path
    const shopUrl = store.getPublicRuntimeConfig().publicConfig.shopURL

    if (!path) {
      return shopUrl
    }

    if (shopUrl.endsWith('/') && path.startsWith('/')) {
      return `${shopUrl}${path.substring(1)}`
    }

    return `${shopUrl}${path}`
  }

  async fetchOffCanvasCategories(categoryIdentifier, fetchByPath) {
    this.logger.debug('Fetching offcanvas categories', 'dataFetchingAll')

    const { data } = fetchByPath
      ? await this.get(this.getOffCanvasCategoriesByPath(categoryIdentifier))
      : await this.get(this.getOffCanvasCategoriesById(categoryIdentifier))

    return this.getConvertedResponse(data)
  }
}

export default ClientAPIService
