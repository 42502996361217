import {
  EXPERIMENT_VARIATION,
  REBRANDING_COM_TOGGLE,
  REBRANDING_IT_TOGGLE,
} from 'shared/experiments/consts'
import { getExperiment } from 'shared/experiments/utils/experiments'
import { type Experiment } from 'types/shopConfig'

export const isRebrandingITActive = (
  experiments: Experiment[] = []
): boolean => {
  const experiment = getExperiment(REBRANDING_IT_TOGGLE, experiments)
  return !!experiment?.isEnabled
}

export const isRebrandingCOMActive = (
  experiments: Experiment[] = []
): boolean => {
  const experiment = getExperiment(REBRANDING_COM_TOGGLE, experiments)

  const isCOMExperiment =
    experiment.variant === EXPERIMENT_VARIATION.V1 ||
    experiment.variant === EXPERIMENT_VARIATION.V2 ||
    experiment.variant === EXPERIMENT_VARIATION.V3 ||
    experiment.variant === EXPERIMENT_VARIATION.V4

  return !!(experiment?.isEnabled && isCOMExperiment)
}

export const isRebrandingCOMColorExperimentActive = (
  experiments: Experiment[] = []
): boolean => {
  const experiment = getExperiment(REBRANDING_COM_TOGGLE, experiments)

  const isColorExperiment =
    experiment.variant === EXPERIMENT_VARIATION.V2 ||
    experiment.variant === EXPERIMENT_VARIATION.V3 ||
    experiment.variant === EXPERIMENT_VARIATION.V4

  return experiment.isEnabled && isColorExperiment
}

/* This function will be removed after the A/B testing for rebranding COM & AT */
export const isRebrandingCOMInformalTextExperimentActive = (
  experiments: Experiment[] = []
): boolean => {
  const experiment = getExperiment(REBRANDING_COM_TOGGLE, experiments)

  const isInformalTextExperiment =
    experiment?.variant === EXPERIMENT_VARIATION.V3

  return !!(experiment?.isEnabled && isInformalTextExperiment)
}

/* This function will be removed after the A/B testing for rebranding COM & AT */
export const isRebrandingCOMFormalTextExperimentActive = (
  experiments: Experiment[] = []
): boolean => {
  const experiment = getExperiment(REBRANDING_COM_TOGGLE, experiments)

  const isFormalTextExperiment = experiment?.variant === EXPERIMENT_VARIATION.V4

  return !!(experiment?.isEnabled && isFormalTextExperiment)
}

/* This function will be removed after the A/B testing for rebranding COM & AT */
export const displayCurrentOrRebrandingCOMExperimentText = (
  experiments: Experiment[],
  textVariants: {
    informal?: string
    formal?: string
  }
): string => {
  const isInformalTextExperimentActive =
    isRebrandingCOMInformalTextExperimentActive(experiments)
  const isFormalTextExperimentActive =
    isRebrandingCOMFormalTextExperimentActive(experiments)

  if (isInformalTextExperimentActive && textVariants.informal) {
    return textVariants.informal
  }
  if (isFormalTextExperimentActive && textVariants.formal) {
    return textVariants.formal
  }
  return ''
}
