// @ts-nocheck
import type { I18n } from '@lingui/core'

// eslint-disable-next-line no-restricted-imports
import type EnhancedLogger from 'server/services/EnhancedLogger'
import {
  CATEGORY_LEAF_SORTING_KEYS,
  DEFAULT_FILTERS,
  HITS_PER_PAGE_PRODUCT_FILTER,
  RELEVANCY_DESC_KEY,
  SORTING_KEYS,
  TOP_SELLER_COUNT_DESC_KEY,
} from 'shared/consts/algolia'
import { areIdsEqual, validate } from 'shared/services/api/helpers/idValidator'
import PimProductService from 'shared/services/api/PimProductService'
import fetchOrFallback from 'shared/utils/fetchOrFallback'
import {
  DisplayableFacetName,
  RangeFacetName,
  SearchRange,
  SearchResults,
  SearchState,
} from 'types/search'
// FIXME CSEAV-9300 needs to be in views folder because of the translation build
import { getTranslation } from 'views/components/organisms/AlgoliaInstantSearch/Algolia/FilterBox/helpers'
import {
  AVERAGE_RATING_DEFAULT_VALUES,
  PRICE_DEFAULT_VALUES,
} from 'views/components/organisms/AlgoliaInstantSearch/ProductFilter/consts'

import { AverageRatingRangeRefinement, SortingItem } from './types'

const getRangeRefinementByName = ({
  name,
  searchState,
}: {
  name: RangeFacetName
  searchState: SearchState
}): SearchRange | null => {
  return searchState?.range?.[name] || null
}

export const getPzns = (searchQuery: string): string[] => {
  try {
    return decodeURIComponent(searchQuery)
      .split(/[\s,+]+/g)
      .filter(Boolean)
  } catch (error) {
    return []
  }
}

const defaultAverageRating = {
  minValue: AVERAGE_RATING_DEFAULT_VALUES.min,
  maxValue: AVERAGE_RATING_DEFAULT_VALUES.max,
}

export const getAverageRatingRangeRefinement = (
  searchState: SearchState
): AverageRatingRangeRefinement => {
  const rating = getRangeRefinementByName({
    name: 'averageRating',
    searchState,
  })

  if (!rating) {
    return defaultAverageRating
  }

  return {
    minValue: Number(rating.min) || defaultAverageRating.minValue,
    maxValue: Number(rating.max) || defaultAverageRating.maxValue,
  }
}

export const findNotIdentifiedPzns = ({
  hits,
  pzns,
}: {
  hits: Record<'upid', string>[]
  pzns: string[]
}): string[] =>
  hits
    .filter(({ upid }) => !pzns.find(pzn => areIdsEqual(upid, pzn)))
    .map(({ upid }) => upid)

export const shouldIgnoreFilters = async ({
  searchQuery,
  pimProductService,
  logger,
}: {
  searchQuery: string
  pimProductService: PimProductService
  logger: EnhancedLogger
}): Promise<boolean> => {
  const pzns = getPzns(searchQuery)
  if (!pzns.length) {
    return false
  }

  const allValid = pzns.every(pzn => validate(pzn)?.valid)
  if (!allValid) {
    return false
  }

  const {
    data: { items },
  } = await pimProductService.fetchMultiplePimProducts(pzns, {
    offset: 0,
    limit: pzns.length,
  })

  const hits = Array.isArray(items) ? items : []

  if (!hits.length && pzns.length === 1) {
    const product = await fetchOrFallback({
      fetchCall: pimProductService.fetchPimProductWithVariants(pzns[0]),
      fallback: null,
      ctx: { logger },
    })

    if (product) {
      hits.push(product)
    }
  }

  if (!hits.length) {
    return false
  }

  const notIdentified = findNotIdentifiedPzns({
    hits,
    pzns,
  })

  return !notIdentified.length
}

export const normalizeRangeInputValue = (value: string | number): number => {
  const normDecimalValue = `${value}`.replace(',', '.')
  const numberValue =
    normDecimalValue && /^(\d+\.)?\d+$/.test(normDecimalValue)
      ? parseFloat(normDecimalValue.replace(/^0+/, ''))
      : 0

  return isNaN(numberValue) || numberValue < 0 ? 0 : numberValue
}

export const getRangeFromResults = (
  searchResults: SearchResults,
  facetName: string
): SearchRange | null => {
  const disjunctiveFacets = searchResults?.disjunctiveFacets

  if (disjunctiveFacets) {
    const facetValue = disjunctiveFacets.find(facet => facet.name === facetName)

    if (facetValue?.stats?.min != undefined && facetValue?.stats?.max) {
      const {
        stats: { min, max },
      } = facetValue

      if (facetName === 'price') {
        return {
          min: Math.round(normalizeRangeInputValue(min)) / 100,
          max: Math.round(normalizeRangeInputValue(max)) / 100,
        }
      } else {
        return { min, max }
      }
    }
  }

  return null
}

export const getDefaultRange = (
  searchState: SearchState,
  searchResults: SearchResults,
  facetName: string
): SearchRange | null => {
  const facetValue = getRangeRefinementByName({ name: facetName, searchState })
  const { min, max } = facetValue || {}

  if ([min, max].some(v => v !== undefined)) {
    return {
      min: Math.round(normalizeRangeInputValue(Number(min))) / 100,
      max: Math.round(normalizeRangeInputValue(Number(max))) / 100,
    }
  }

  return getRangeFromResults(searchResults, facetName)
}

export const getDefaultHitsPerPage = (searchState: SearchState): number =>
  searchState?.hitsPerPage ?? HITS_PER_PAGE_PRODUCT_FILTER

const getSortingIndex = (indexName: string, key: string): string =>
  `${indexName}_${key}`

export const getCategoryPageDefaultSortingIndex = (indexName: string): string =>
  getSortingIndex(indexName, TOP_SELLER_COUNT_DESC_KEY)

export const getSortingItems = ({
  indexName,
  i18n,
  isSearchResultsPage = true,
}: {
  indexName: string
  i18n: I18n
  isSearchResultsPage: boolean
}): SortingItem[] =>
  (isSearchResultsPage ? SORTING_KEYS : CATEGORY_LEAF_SORTING_KEYS).map(
    key => ({
      value:
        key !== RELEVANCY_DESC_KEY
          ? getSortingIndex(indexName, key)
          : indexName,
      label: getTranslation(key, i18n),
      default:
        key ===
        (isSearchResultsPage ? RELEVANCY_DESC_KEY : TOP_SELLER_COUNT_DESC_KEY),
    })
  )

export const injectFilters = (filters: string): string => {
  if (filters) {
    if (
      filters.includes('brandCode') ||
      (filters.includes('(categoryCodes') && filters.includes('created')) ||
      filters.includes('objectID:')
    ) {
      return `${filters} AND ${DEFAULT_FILTERS}`
    }

    return `(${filters}) AND ${DEFAULT_FILTERS}`
  }

  return DEFAULT_FILTERS
}

export const isAnyFilterAdjusted = (searchState: SearchState = {}): boolean => {
  const { refinementList = {}, menu = {}, toggle = {}, range } = searchState

  if (Object.values({ ...refinementList, ...menu, ...toggle }).some(Boolean)) {
    return true
  }

  if (range) {
    const { price, ...rangeNoPrice } = range
    let nonDefaultPrice = false

    if (price) {
      const { min, max } = price
      nonDefaultPrice =
        (min !== undefined && Number(min) !== PRICE_DEFAULT_VALUES.min) ||
        (max !== undefined && Number(max) !== PRICE_DEFAULT_VALUES.max)
    }

    const hasValidValue = (value: unknown) =>
      value !== undefined && !isNaN(Number(value))

    return (
      nonDefaultPrice ||
      Object.keys(rangeNoPrice).some(rangeGroup =>
        Object.values(rangeNoPrice[rangeGroup]!).some(hasValidValue)
      )
    )
  }

  return false
}

export const getFiltersOrder = (
  searchResults: SearchResults
): DisplayableFacetName[] => {
  return searchResults?.renderingContent?.facetOrdering?.facets?.order || []
}

export const isFilterApplied = (
  searchState: SearchState,
  defaultIndex: string
): boolean => {
  const { sortBy: index } = searchState

  return Boolean(index !== defaultIndex || isAnyFilterAdjusted(searchState))
}
